@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Ubuntu:wght@700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.title {
  font-family: "Ubuntu", sans-serif;
  color: rgb(51 65 85);
}

.subtitle {
  font-family: "Ubuntu", sans-serif;
  color: rgb(79 70 229);
  font-weight: 800;
  text-transform: uppercase;
}

.body {
  font-family: "Open Sans", sans-serif;
  color: rgb(71 85 105);
  width: 100%;
}

.button {
  font-family: "Ubuntu", sans-serif;
  color: #fff;
  font-weight: 400;
}

.nav {
  font-family: "Open Sans", sans-serif;
  color: rgb(51, 65, 85);
  font-weight: 500;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 50px;
}

.event-card{
  max-width: 400px;
  outline: 1px solid black;
  margin: 15px;
  border-radius: 15px;
}

.carousel-image{
  max-height: 300px !important;
  float: none !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

#mc_embed_signup form {
 
  font: 14px Arial, sans-serif;
  margin: 0 !important;
  padding: 0 !important;
  
  flex-direction: row;
  width: 300px;
}

#mc_embed_shell{
  margin: 0 !important;
  padding: 0 !important;
}

#mc_embed_signup .mc-field-group input {display:block; width:100%; padding:10px 0; text-indent:2%; border: 0; }

#mc-embedded-subscribe{
  clear:both; background-color: #808080 !important; border: 0 none; border-radius:4px; transition: all 0.23s ease-in-out 0s; color: #FFFFFF; cursor: pointer; display: inline-block; font-size:15px; font-weight: normal; height: 32px; line-height: 32px; margin: 0 5px 10px 0; padding: 0 22px; text-align: center; text-decoration: none; vertical-align: top; white-space: nowrap; width: fit-content; width: -moz-fit-content;}

#mc-embedded-subscribe:hover{
  clear:both; background-color: #000000 !important; border: 0 none; border-radius:4px; transition: all 0.23s ease-in-out 0s; color: #FFFFFF; cursor: pointer; display: inline-block; font-size:15px; font-weight: normal; height: 32px; line-height: 32px; margin: 0 5px 10px 0; padding: 0 22px; text-align: center; text-decoration: none; vertical-align: top; white-space: nowrap; width: fit-content; width: -moz-fit-content;}
